import { Field, Formik } from "formik";
import { useState } from "react";
import styled from "styled-components";
import {
  calculateInheritance,
  InheritanceShares,
  generateFamily,
  calculateTotalSharesIssued,
} from "../../lib/inheritance";

const Container = styled.div`
  margin: 20px 20px 80px 20px;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 200px;

  & > * {
    margin: 20px 20px 20px 0px;
  }
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export type FamilyValues = {
  husband: string[];
  mother: string[];
  father: string[];
  wives: number;
  sons: number;
  daughters: number;
  grandsons: number;
  granddaughters: number;
  brothers: number;
  sisters: number;
  paternalGrandfathers: number;
  paternalGrandmothers: number;
  maternalGrandmothers: number;
  paternalBrothers: number;
  paternalSisters: number;
  maternalBrothers: number;
  maternalSisters: number;
  fullBrothersSons: number;
  paternalBrothersSons: number;
  fullPaternalUncles: number;
  fathersPaternalBrothers: number;
  fullPaternalUnclesSons: number;
  fathersPaternalBrothersSons: number;
};

export const Calculator = () => {
  const [shares, setShares] = useState<InheritanceShares>({});
  const totalAllocated = calculateTotalSharesIssued(shares);

  return (
    <Container>
      <h1>IFG Islamic Inheritance Calculator</h1>

      <Formik
        initialValues={{
          husband: [],
          mother: [],
          father: [],
          wives: 0,
          sons: 0,
          daughters: 0,
          grandsons: 0,
          granddaughters: 0,
          brothers: 0,
          sisters: 0,
          paternalGrandfathers: 0,
          paternalGrandmothers: 0,
          maternalGrandmothers: 0,
          paternalBrothers: 0,
          paternalSisters: 0,
          maternalBrothers: 0,
          maternalSisters: 0,
          fullBrothersSons: 0,
          paternalBrothersSons: 0,
          fullPaternalUncles: 0,
          fathersPaternalBrothers: 0,
          fullPaternalUnclesSons: 0,
          fathersPaternalBrothersSons: 0,
        }}
        onSubmit={async values => {
          console.log(values);
          const family = generateFamily(values);
          const { shares } = calculateInheritance(family);
          console.log(shares);
          setShares(shares);
        }}
      >
        {({ values, submitForm }) => {
          return (
            <>
              <FormContainer>
                <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field type="checkbox" name="husband" value="checked" />
                    Husband
                  </label>
                </FormItemContainer>

                <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field type="checkbox" name="father" value="checked" />
                    Father
                  </label>
                </FormItemContainer>

                <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field type="checkbox" name="mother" value="checked" />
                    Mother
                  </label>
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="wives">Wives</label>
                  <Field
                    id="wives"
                    name="wives"
                    placeholder="0"
                    type="number"
                    value={values.wives}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="sons">Sons</label>
                  <Field
                    id="sons"
                    name="sons"
                    placeholder="0"
                    type="number"
                    value={values.sons}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="daughters">Daughters</label>
                  <Field
                    id="daughters"
                    name="daughters"
                    placeholder="0"
                    type="number"
                    value={values.daughters}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="grandsons">Grandsons (Son's sons)</label>
                  <Field
                    id="grandsons"
                    name="grandsons"
                    placeholder="0"
                    type="number"
                    value={values.grandsons}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="granddaughters">
                    Granddaughters (Son's daughters)
                  </label>
                  <Field
                    id="granddaughters"
                    name="granddaughters"
                    placeholder="0"
                    type="number"
                    value={values.granddaughters}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="brothers">Brothers</label>
                  <Field
                    id="brothers"
                    name="brothers"
                    placeholder="0"
                    type="number"
                    value={values.brothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="sisters">Sisters</label>
                  <Field
                    id="sisters"
                    name="sisters"
                    placeholder="0"
                    type="number"
                    value={values.sisters}
                    min={0}
                  />
                </FormItemContainer>

                {/* <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field
                      type="checkbox"
                      name="paternalGrandfather"
                      value="checked"
                    />
                    Paternal Grandfather
                  </label>
                </FormItemContainer>

                <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field
                      type="checkbox"
                      name="paternalGrandmother"
                      value="checked"
                    />
                    Paternal Grandmother
                  </label>
                </FormItemContainer>

                <FormItemContainer
                  role="group"
                  aria-labelledby="checkbox-group"
                >
                  <label>
                    <Field
                      type="checkbox"
                      name="maternalGrandmother"
                      value="checked"
                    />
                    Maternal Grandmother
                  </label>
                </FormItemContainer> */}

                <FormItemContainer>
                  <label htmlFor="paternalGrandfathers">
                    Paternal Grandfathers
                  </label>
                  <Field
                    id="paternalGrandfathers"
                    name="paternalGrandfathers"
                    placeholder="0"
                    type="number"
                    value={values.paternalGrandfathers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="paternalGrandmothers">
                    Paternal Grandmothers
                  </label>
                  <Field
                    id="paternalGrandmothers"
                    name="paternalGrandmothers"
                    placeholder="0"
                    type="number"
                    value={values.paternalGrandmothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="maternalGrandmothers">
                    Maternal Grandmothers
                  </label>
                  <Field
                    id="maternalGrandmothers"
                    name="maternalGrandmothers"
                    placeholder="0"
                    type="number"
                    value={values.maternalGrandmothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="paternalSisters">Paternal Sisters</label>
                  <Field
                    id="paternalSisters"
                    name="paternalSisters"
                    placeholder="0"
                    type="number"
                    value={values.paternalSisters}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="paternalBrothers">Paternal Brothers</label>
                  <Field
                    id="paternalBrothers"
                    name="paternalBrothers"
                    placeholder="0"
                    type="number"
                    value={values.paternalBrothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="maternalSisters">Maternal Sisters</label>
                  <Field
                    id="maternalSisters"
                    name="maternalSisters"
                    placeholder="0"
                    type="number"
                    value={values.maternalSisters}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="maternalBrothers">Maternal Brothers</label>
                  <Field
                    id="maternalBrothers"
                    name="maternalBrothers"
                    placeholder="0"
                    type="number"
                    value={values.maternalBrothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="fullBrothersSons">Full Brother's Sons</label>
                  <Field
                    id="fullBrothersSons"
                    name="fullBrothersSons"
                    placeholder="0"
                    type="number"
                    value={values.fullBrothersSons}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="paternalBrothersSons">
                    Paternal Brother's Sons
                  </label>
                  <Field
                    id="paternalBrothersSons"
                    name="paternalBrothersSons"
                    placeholder="0"
                    type="number"
                    value={values.paternalBrothersSons}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="fullPaternalUncles">
                    Full Paternal Uncles
                  </label>
                  <Field
                    id="fullPaternalUncles"
                    name="fullPaternalUncles"
                    placeholder="0"
                    type="number"
                    value={values.fullPaternalUncles}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="fathersPaternalBrothers">
                    Father's Paternal Brothers
                  </label>
                  <Field
                    id="fathersPaternalBrothers"
                    name="fathersPaternalBrothers"
                    placeholder="0"
                    type="number"
                    value={values.fathersPaternalBrothers}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="fullPaternalUnclesSons">
                    Full Paternal Uncle's Sons
                  </label>
                  <Field
                    id="fullPaternalUnclesSons"
                    name="fullPaternalUnclesSons"
                    placeholder="0"
                    type="number"
                    value={values.fullPaternalUnclesSons}
                    min={0}
                  />
                </FormItemContainer>

                <FormItemContainer>
                  <label htmlFor="fathersPaternalBrothersSons">
                    Father's Paternal Brother's Sons
                  </label>
                  <Field
                    id="fathersPaternalBrothersSons"
                    name="fathersPaternalBrothersSons"
                    placeholder="0"
                    type="number"
                    value={values.fathersPaternalBrothersSons}
                    min={0}
                  />
                </FormItemContainer>
              </FormContainer>

              <button onClick={submitForm}>Calculate</button>
            </>
          );
        }}
      </Formik>
      <div>
        {Object.keys(shares).map(key => {
          return (
            <p key={key}>
              {key +
                ": " +
                shares[key].toFraction() +
                ": " +
                ((shares[key].n / shares[key].d) * 100).toFixed(2) +
                "%"}
            </p>
          );
        })}
        {totalAllocated.compare(0) !== 0 && totalAllocated.compare(1) < 0 ? (
          <p style={{ color: "red" }}>
            Warning: less than 100% allocated to inheritors
          </p>
        ) : (
          <p></p>
        )}
      </div>
    </Container>
  );
};
