import Fraction from "fraction.js";
import { FamilyValues } from "../../components/calculator";

export type FamilyMember = {
  // relation: string;
  name: string;
  // overEighteen: boolean;
  // specialField: string;
  // married: boolean;
};

export type Family = {
  brothers: FamilyMember[];
  sisters: FamilyMember[];
  husband?: FamilyMember;
  wives: FamilyMember[];
  daughters: FamilyMember[];
  sons: FamilyMember[];
  granddaughters: FamilyMember[];
  grandsons: FamilyMember[];
  father?: FamilyMember;
  mother?: FamilyMember;
  paternalGrandfathers: FamilyMember[];
  paternalGrandmothers: FamilyMember[];
  maternalGrandmothers: FamilyMember[];
  paternalSisters: FamilyMember[];
  paternalBrothers: FamilyMember[];
  maternalSisters: FamilyMember[];
  maternalBrothers: FamilyMember[];

  fullBrothersSons: FamilyMember[];
  paternalBrothersSons: FamilyMember[];
  fullPaternalUncles: FamilyMember[];
  fathersPaternalBrothers: FamilyMember[];
  fullPaternalUnclesSons: FamilyMember[];
  fathersPaternalBrothersSons: FamilyMember[];
};

export type InheritanceShares = Record<string, Fraction>;

const generateFamilyMember = (name: string): FamilyMember => {
  return {
    name,
  };
};

const generateArrayFromNum = (num: number, familyMemberName: string) => {
  return Array(num)
    .fill(0)
    .map((_, i) => generateFamilyMember(`${familyMemberName} ${i + 1}`));
};

export const generateFamily = (values: FamilyValues): Family => {
  const family: Family = {
    brothers: generateArrayFromNum(values.brothers, "Brother"),
    sisters: generateArrayFromNum(values.sisters, "Sister"),
    daughters: generateArrayFromNum(values.daughters, "Daughter"),
    sons: generateArrayFromNum(values.sons, "Son"),
    granddaughters: generateArrayFromNum(
      values.granddaughters,
      "Granddaughter"
    ),
    grandsons: generateArrayFromNum(values.grandsons, "Grandson"),
    wives: generateArrayFromNum(values.wives, "Wife"),
    paternalBrothers: generateArrayFromNum(
      values.paternalBrothers,
      "Paternal Brother"
    ),
    paternalSisters: generateArrayFromNum(
      values.paternalSisters,
      "Paternal Sister"
    ),
    maternalBrothers: generateArrayFromNum(
      values.maternalBrothers,
      "Maternal Brother"
    ),
    maternalSisters: generateArrayFromNum(
      values.maternalSisters,
      "Maternal Sister"
    ),
    fullBrothersSons: generateArrayFromNum(
      values.fullBrothersSons,
      "Full Brother's Son"
    ),
    paternalBrothersSons: generateArrayFromNum(
      values.paternalBrothersSons,
      "Paternal Brother's Son"
    ),
    fullPaternalUncles: generateArrayFromNum(
      values.fullPaternalUncles,
      "Full Paternal Uncle"
    ),
    fathersPaternalBrothers: generateArrayFromNum(
      values.fathersPaternalBrothers,
      "Father's Paternal Brother"
    ),
    fullPaternalUnclesSons: generateArrayFromNum(
      values.fullPaternalUnclesSons,
      "Full Paternal Uncle's Son"
    ),
    fathersPaternalBrothersSons: generateArrayFromNum(
      values.fathersPaternalBrothersSons,
      "Father's Paternal Brother's Son"
    ),
    paternalGrandfathers: generateArrayFromNum(
      values.paternalGrandfathers,
      "Paternal Grandfather"
    ),
    paternalGrandmothers: generateArrayFromNum(
      values.paternalGrandmothers,
      "Paternal Grandmother"
    ),
    maternalGrandmothers: generateArrayFromNum(
      values.maternalGrandmothers,
      "Maternal Grandmother"
    ),
  };

  if (values.husband.length > 0)
    family.husband = generateFamilyMember("Husband");
  if (values.mother.length > 0) family.mother = generateFamilyMember("Mother");
  if (values.father.length > 0) family.father = generateFamilyMember("Father");
  // if (values.paternalGrandfather)
  //   family.paternalGrandfather = generateFamilyMember("Paternal Grandfather");
  // if (values.paternalGrandmother.length > 0)
  //   family.paternalGrandmother = generateFamilyMember("Paternal Grandmother");
  // if (values.maternalGrandmother.length > 0)
  //   family.maternalGrandmother = generateFamilyMember("Maternal Grandmother");

  return family;
};

const hasOffspring = (family: Family): boolean => {
  return (
    family.sons.length > 0 ||
    family.daughters.length > 0 ||
    family.granddaughters.length > 0 ||
    family.grandsons.length > 0
  );
};

const hasMultipleSiblings = (family: Family): boolean => {
  return (
    family.sisters.length +
      family.brothers.length +
      family.maternalSisters.length +
      family.maternalBrothers.length +
      family.paternalBrothers.length +
      family.paternalSisters.length >
    1
  );
};

// const hasMalePaternalAncestor = (family: Family): boolean => {
//   return !!(family.father || family.paternalGrandfather);
// };

const hasMaleHeir = (family: Family): boolean => {
  return family.sons.length > 0 || family.grandsons.length > 0;
};

const hasFemaleHeir = (family: Family): boolean => {
  return family.daughters.length > 0 || family.granddaughters.length > 0;
};

const distributeShares = (
  familyMembers: FamilyMember[],
  shares: InheritanceShares,
  totalShareAmount: Fraction
) => {
  // Avoid divide by 0 edge case
  if (familyMembers.length === 0) return;

  familyMembers.map(
    member => (shares[member.name] = totalShareAmount.div(familyMembers.length))
  );
};

const assignShare = (
  familyMembers: FamilyMember[],
  shares: InheritanceShares,
  totalShareAmount: Fraction
) => {
  // Avoid divide by 0 edge case
  if (familyMembers.length === 0) return;

  familyMembers.map(member => {
    if (!shares[member.name]) {
      shares[member.name] = new Fraction(0);
    }

    shares[member.name] = shares[member.name].add(totalShareAmount);

    return member;
  });
};

export const calculateTotalSharesIssued = (
  shares: InheritanceShares
): Fraction => {
  let total = new Fraction(0);
  for (let key in shares) {
    total = total.add(shares[key]);
  }

  return total;
};

export const calculateTotalShareAmountRemaining = (
  shares: InheritanceShares
): Fraction => {
  const total = calculateTotalSharesIssued(shares);
  return new Fraction(1).sub(total);
};

const calculateMaleAndFemaleShares = (
  males: FamilyMember[],
  females: FamilyMember[],
  shares: InheritanceShares
): { maleShare: Fraction; femaleShare: Fraction } => {
  const totalOutstandingShares =
    females.length > 0 ? females.length + males.length * 2 : males.length;

  if (totalOutstandingShares.valueOf() === 0)
    return { maleShare: new Fraction(0), femaleShare: new Fraction(0) };

  const remaining = calculateTotalShareAmountRemaining(shares);

  const eachShare = remaining.div(totalOutstandingShares);
  // console.log("Remaining " + remaining.toFraction());
  // console.log("Each Share " + eachShare.toFraction());
  // console.log("Total " + totalOutstandingShares);
  const maleShare = females.length > 0 ? eachShare.mul(2) : eachShare;
  const femaleShare = eachShare;

  return {
    maleShare: males.length > 0 ? maleShare : new Fraction(0),
    femaleShare: females.length > 0 ? femaleShare : new Fraction(0),
  };
};

const normaliseShares = (family: Family, shares: InheritanceShares) => {
  const total = calculateTotalSharesIssued(shares);

  if (total.compare(1) < 0) {
    // Exclude wife or husband
    let excludedTotal = total;
    if (family.wives.length > 0) {
      family.wives.map((wife, index) => {
        excludedTotal = excludedTotal.sub(shares[`Wife ${index + 1}`]);
        return wife;
      });
    }

    if (family.husband) {
      excludedTotal = excludedTotal.sub(shares["Husband"]);
    }

    const remaining = new Fraction(1).sub(total);

    for (let key in shares) {
      if (!key.includes("Wife") && !key.includes("Husband")) {
        const portion = shares[key].div(excludedTotal);
        shares[key] = shares[key].add(remaining.mul(portion));
      }
    }
  } else {
    for (let key in shares) {
      shares[key] = shares[key].div(total);
    }
  }
};

const calculateHusbandFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (!family.husband) return;
  if (family.wives.length > 0) return;

  shares[family.husband.name] = hasOffspring(family)
    ? new Fraction(1).div(4)
    : new Fraction(1).div(2);
};

const calculateWifeFixedShare = (family: Family, shares: InheritanceShares) => {
  if (family.husband) return;

  const totalShareAmount = hasOffspring(family)
    ? new Fraction(1).div(8)
    : new Fraction(1).div(4);

  distributeShares(family.wives, shares, totalShareAmount);
};

const calculatePaternalGrandmotherFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (family.paternalGrandmothers.length === 0) return;

  // Set to 0 initially
  assignShare(family.paternalGrandmothers, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return;
  if (family.mother) return;

  if (family.maternalGrandmothers.length > 0) {
    distributeShares(
      family.paternalGrandmothers,
      shares,
      new Fraction(1).div(12)
    );
  } else {
    distributeShares(
      family.paternalGrandmothers,
      shares,
      new Fraction(1).div(6)
    );
  }
};

const calculateMaternalGrandmotherFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (family.maternalGrandmothers.length === 0) return;

  // Set to 0 initially
  assignShare(family.maternalGrandmothers, shares, new Fraction(0));

  // Blocking rules
  if (family.mother) return;

  if (family.paternalGrandmothers.length > 0 && !family.father) {
    distributeShares(
      family.maternalGrandmothers,
      shares,
      new Fraction(1).div(12)
    );
  } else {
    distributeShares(
      family.maternalGrandmothers,
      shares,
      new Fraction(1).div(6)
    );
  }
};

const calculateMotherFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (!family.mother) return;

  shares[family.mother.name] = new Fraction(0);

  if (
    !hasOffspring(family) &&
    (family.wives.length > 0 || family.husband) &&
    family.father &&
    !hasMultipleSiblings(family)
  ) {
    const remaining = calculateTotalShareAmountRemaining(shares);
    shares[family.mother.name] = remaining.div(3);
    return;
  }

  if (!hasOffspring(family) && !hasMultipleSiblings(family)) {
    shares[family.mother.name] = new Fraction(1).div(3);
    return;
  }

  if (hasOffspring(family) || hasMultipleSiblings(family)) {
    shares[family.mother.name] = new Fraction(1).div(6);
    return;
  }
};

const calculateFatherFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (!family.father) return;

  shares[family.father.name] = new Fraction(0);

  if (hasMaleHeir(family) || hasFemaleHeir(family)) {
    shares[family.father.name] = new Fraction(1).div(6);
  }
};

const calculatePaternalGrandfatherFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  if (family.paternalGrandfathers.length === 0) return;

  // Set to 0 initially
  assignShare(family.paternalGrandfathers, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return;

  if (hasMaleHeir(family) || hasFemaleHeir(family)) {
    distributeShares(
      family.paternalGrandfathers,
      shares,
      new Fraction(1).div(6)
    );
  }
};

const calculateDaughtersFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  // Set to 0 initially
  assignShare(family.daughters, shares, new Fraction(0));

  // If there are sons, daughters get inheritance via taseeb
  if (family.sons.length !== 0) return;

  if (family.daughters.length === 1) {
    shares[family.daughters[0].name] = new Fraction(1).div(2);
    return;
  }

  const totalShareAmount = new Fraction(2).div(3);
  distributeShares(family.daughters, shares, totalShareAmount);
};

const calculateMaternalSiblingsFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  // Set to 0 initially
  assignShare(
    [...family.maternalBrothers, ...family.maternalSisters],
    shares,
    new Fraction(0)
  );

  // Blocking rules
  if (family.sons.length > 0) return;
  if (family.father) return;
  if (family.paternalGrandfathers.length > 0) return;
  if (family.daughters.length > 0) return;
  if (family.granddaughters.length > 0) return;
  if (family.grandsons.length > 0) return;

  // Special case when only one maternal sibling
  if (family.maternalBrothers.length + family.maternalSisters.length === 1) {
    assignShare(
      [...family.maternalBrothers, ...family.maternalSisters],
      shares,
      new Fraction(1).div(6)
    );
    return;
  }

  distributeShares(
    [...family.maternalBrothers, ...family.maternalSisters],
    shares,
    new Fraction(1).div(3)
  );
};

const calculateGranddaughtersFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  assignShare(family.granddaughters, shares, new Fraction(0));

  // Blocking rules
  if (family.sons.length > 0) return;
  if (family.daughters.length > 1) return;

  // Granddaughters get their inheritance via taseeb when grandsons exist
  if (family.grandsons.length > 0) return;

  const totalShareAmount =
    family.daughters.length === 1
      ? new Fraction(1).div(6)
      : family.granddaughters.length === 1
      ? new Fraction(1).div(2)
      : new Fraction(2).div(3);

  distributeShares(family.granddaughters, shares, totalShareAmount);
};

const calculateSistersFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  assignShare(family.sisters, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return;
  if (family.paternalGrandfathers.length > 0) return;
  if (family.sons.length > 0) return;
  if (family.grandsons.length > 0) return;

  // Sisters get their inheritance via taseeb when brothers exist
  if (family.brothers.length > 0) return;
  // Sisters get their inheritance via taseeb when daughters or granddaughters exist
  if (family.daughters.length > 0 || family.grandsons.length > 0) return;

  const totalShareAmount =
    family.sisters.length === 1
      ? new Fraction(1).div(2)
      : new Fraction(2).div(3);

  distributeShares(family.sisters, shares, totalShareAmount);
};

const calculatePaternalSistersFixedShare = (
  family: Family,
  shares: InheritanceShares
) => {
  assignShare(family.paternalSisters, shares, new Fraction(0));

  // Blocking rules for paternal sister
  if (family.father) return;
  if (family.paternalGrandfathers.length > 0) return;
  if (family.sons.length > 0) return;
  if (family.grandsons.length > 0) return;
  if (family.brothers.length > 0) return;
  if (family.daughters.length !== 0) return;
  if (family.granddaughters.length !== 0) return;

  // Paternal Sisters get their inheritance via taseeb when Full Brother's Sons exist
  if (family.paternalBrothers.length > 0) return;

  let totalPaternalSisterShareAmount = new Fraction(0);
  if (family.sisters.length === 0) {
    totalPaternalSisterShareAmount =
      family.paternalSisters.length === 1
        ? new Fraction(1).div(2)
        : new Fraction(2).div(3);
  } else if (family.sisters.length === 1) {
    totalPaternalSisterShareAmount = new Fraction(1).div(6);
  }

  distributeShares(
    family.paternalSisters,
    shares,
    totalPaternalSisterShareAmount
  );
};

const calculateFatherTaseeb = (family: Family, shares: InheritanceShares) => {
  if (!family.father) return [];

  // No ta'seeb if male descendants exist
  if (family.sons.length > 0) return [];
  if (family.grandsons.length > 0) return [];

  return [family.father];
};

const calculatePaternalGrandfatherTaseeb = (
  family: Family,
  shares: InheritanceShares
) => {
  if (family.paternalGrandfathers.length === 0) return [];

  // Blocking rules
  if (family.father) return [];

  // No ta'seeb if male descendants exist
  if (family.sons.length > 0) return [];
  if (family.grandsons.length > 0) return [];

  return family.paternalGrandfathers;
};

const calculateSonsTaseeb = (family: Family, shares: InheritanceShares) => {
  return family.sons;
};

const calculateDaughtersTaseeb = (
  family: Family,
  shares: InheritanceShares
) => {
  // No ta'seeb if there are no sons
  if (family.sons.length === 0) return [];

  return family.daughters;
};

const calculateGrandsonsTaseeb = (
  family: Family,
  shares: InheritanceShares
) => {
  assignShare(family.grandsons, shares, new Fraction(0));

  // Blocking rules
  if (family.sons.length > 0) return [];

  return family.grandsons;
};

const calculateGranddaughtersTaseeb = (
  family: Family,
  shares: InheritanceShares
) => {
  // Blocking rules
  if (family.sons.length > 0) return [];

  // No ta'seeb if there are no grandsons
  if (family.grandsons.length === 0) return [];

  return family.granddaughters;
};

const calculateBrothersAndSistersTaseeb = (
  family: Family,
  shares: InheritanceShares
): { maleM: FamilyMember[]; femaleM: FamilyMember[] } => {
  assignShare(family.brothers, shares, new Fraction(0));
  assignShare(family.paternalBrothers, shares, new Fraction(0));
  assignShare(family.fullBrothersSons, shares, new Fraction(0));
  assignShare(family.paternalBrothersSons, shares, new Fraction(0));
  assignShare(family.fullPaternalUncles, shares, new Fraction(0));
  assignShare(family.fathersPaternalBrothers, shares, new Fraction(0));
  assignShare(family.fullPaternalUnclesSons, shares, new Fraction(0));
  assignShare(family.fathersPaternalBrothersSons, shares, new Fraction(0));

  // Blocking rules
  if (family.father) return { maleM: [], femaleM: [] };
  if (family.paternalGrandfathers.length > 0) return { maleM: [], femaleM: [] };
  if (family.sons.length > 0) return { maleM: [], femaleM: [] };
  if (family.grandsons.length > 0) return { maleM: [], femaleM: [] };

  if (family.brothers.length > 0) {
    return { maleM: family.brothers, femaleM: family.sisters };
  }

  if (
    family.sisters.length > 0 &&
    (family.daughters.length > 0 || family.granddaughters.length > 0)
  ) {
    return { maleM: [], femaleM: family.sisters };
  }

  // paternal brothers (inherits with paternal sisters)
  if (family.paternalBrothers.length > 0) {
    return { maleM: family.paternalBrothers, femaleM: family.paternalSisters };
  }

  // paternal sister
  if (
    family.paternalSisters.length > 0 &&
    (family.daughters.length > 0 || family.granddaughters.length > 0) &&
    family.sisters.length === 0
  ) {
    return { maleM: [], femaleM: family.paternalSisters };
  }

  if (family.fullBrothersSons.length > 0) {
    return { maleM: family.fullBrothersSons, femaleM: [] };
  }

  if (family.paternalBrothersSons.length > 0) {
    return {
      maleM: family.paternalBrothersSons,
      femaleM: [],
    };
  }

  if (family.fullPaternalUncles.length > 0) {
    return {
      maleM: family.fullPaternalUncles,
      femaleM: [],
    };
  }

  if (family.fathersPaternalBrothers.length > 0) {
    return {
      maleM: family.fathersPaternalBrothers,
      femaleM: [],
    };
  }

  if (family.fullPaternalUnclesSons.length > 0) {
    return {
      maleM: family.fullPaternalUnclesSons,
      femaleM: [],
    };
  }

  if (family.fathersPaternalBrothersSons.length > 0) {
    return {
      maleM: family.fathersPaternalBrothersSons,
      femaleM: [],
    };
  }

  return { maleM: [], femaleM: [] };
};

const calculateFixedAmounts = (family: Family, shares: InheritanceShares) => {
  calculateHusbandFixedShare(family, shares);
  calculateWifeFixedShare(family, shares);

  calculatePaternalGrandmotherFixedShare(family, shares);
  calculateMaternalGrandmotherFixedShare(family, shares);

  calculateMotherFixedShare(family, shares);
  calculateFatherFixedShare(family, shares);

  calculatePaternalGrandfatherFixedShare(family, shares);

  calculateDaughtersFixedShare(family, shares);
  calculateMaternalSiblingsFixedShare(family, shares);
  calculateGranddaughtersFixedShare(family, shares);

  calculateSistersFixedShare(family, shares);
  calculatePaternalSistersFixedShare(family, shares);
};

const calculateTaseeb = (family: Family, shares: InheritanceShares) => {
  const maleMembers: FamilyMember[] = [];
  const femaleMembers: FamilyMember[] = [];

  maleMembers.push(...calculateFatherTaseeb(family, shares));
  maleMembers.push(...calculatePaternalGrandfatherTaseeb(family, shares));

  maleMembers.push(...calculateSonsTaseeb(family, shares));
  femaleMembers.push(...calculateDaughtersTaseeb(family, shares));
  femaleMembers.push(...calculateGrandsonsTaseeb(family, shares));
  femaleMembers.push(...calculateGranddaughtersTaseeb(family, shares));

  const { maleM, femaleM } = calculateBrothersAndSistersTaseeb(family, shares);

  maleMembers.push(...maleM);
  femaleMembers.push(...femaleM);

  const totalShareRemaining = calculateTotalShareAmountRemaining(shares);
  // If more than whole estate is allocated, no ta'seeb
  if (totalShareRemaining.compare(0) < 0) return;

  const { maleShare, femaleShare } = calculateMaleAndFemaleShares(
    maleMembers,
    femaleMembers,
    shares
  );

  assignShare(maleMembers, shares, maleShare);
  assignShare(femaleMembers, shares, femaleShare);

  // console.log(shares);
};

export const calculateInheritance = (
  family: Family
): { shares: InheritanceShares; err?: Error } => {
  const inheritanceShares: InheritanceShares = {};

  // Calculate Fixed Amounts
  calculateFixedAmounts(family, inheritanceShares);
  // Calculate Tas'eeb
  calculateTaseeb(family, inheritanceShares);
  // Normalise if more than 100% of estate allocated
  normaliseShares(family, inheritanceShares);

  const total = calculateTotalSharesIssued(inheritanceShares);

  return {
    shares: inheritanceShares,
    err:
      total.valueOf() < 1
        ? new Error("Less than 100% allocated to inheritors")
        : undefined,
  };
};
